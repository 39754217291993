import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Gallery = ({ data }) => {
  const { title, description, images, tags = [] } = data.contentfulGallery;
  return (
    <Layout>
      <SEO title={title} />
      <div className="blogpost">
        <h1>{title}</h1>
        <p className="body-text" dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.html}} />
        {images.length > 0 && (
            images.map(image => 
              <React.Fragment>
                <img alt={image.description || title} src={image.fixed.src} srcset={`${image.file.url} 2x`} />
                <figcaption>
                  {image.description}
                </figcaption>
              </React.Fragment>
            )
        )}
        {tags.length > 0 && (
          <div className="tags">
          {tags.map(tag => (
            <span className="tag" key={tag}>
              {tag}
            </span>
          ))}
        </div>
        )}
        <Link to="/posts">View more posts</Link>
        <Link to="/">Back to Home</Link>
      </div>
    </Layout>
  );
};

export default Gallery;

// Gatsby will run this query at runtime and will pass a 
// data prop to BlogPost containing the Contentful data
export const pageQuery = graphql`
  query($slug: String!) {
    contentfulGallery(slug: { eq: $slug }) {
      title
      slug
      description {
        childMarkdownRemark {
          html
        }
      }
      images {
        description
        file {
          url
        }
        fixed(width: 630, quality:100) {
          src
        }
      }
      tags
    }
  }
`;